export const en = {
	'Найдите лучший склад для хранения рыбы и морепродуктов по всей России':
		'Find the best warehouse for fish and seafood storage across Russia',
	'Поиск склада': 'Find a warehouse',
	Местоположение: 'Location',
	'Введите регион': 'Enter region',
	'Радиус поиска, км': 'Search radius, km',
	'Сначала укажите местоположение': 'First, specify the location',
	'Введите город или регион': 'Enter city or region',
	Дата: 'Data',
	'Выберите дату': 'Select date',
	'Температурный режим': 'Temperature mode',
	'Выберите режим': 'Select mode',
	'Масса брутто, кг': 'Net weight, kg',
	'Введите массу': 'Select weight',
	'Подобрать склад': 'Find a warehouse',
	'Не выбрано': 'Not selected',
};
