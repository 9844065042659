import { SVGAttributes } from 'react';

export const StorageIcon = (props: SVGAttributes<SVGElement>) => (
	<svg
		width="2.5rem"
		height="3.1rem"
		viewBox="0 0 25 31"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_3912_103819)">
			<path
				d="M1.03906 20.4176L1.12342 28.0913C1.13189 28.8619 1.76244 29.4797 2.53178 29.4712L22.0342 29.2561C22.8035 29.2476 23.4204 28.6161 23.4119 27.8455L23.3275 20.1719"
				stroke="#14489D" strokeWidth="1.84688" strokeLinecap="round" strokeLinejoin="round"
			/>
			<path
				d="M21.804 8.32821L2.30161 8.54325C1.53226 8.55175 0.915444 9.18325 0.923914 9.95384L0.969934 14.1395C0.978404 14.91 1.60895 15.5278 2.37829 15.5193L21.8807 15.3043C22.6501 15.2958 23.2668 14.6643 23.2584 13.8937L23.2124 9.70809C23.2039 8.9375 22.5734 8.31975 21.804 8.32821Z"
				fill="#CEE2FF" stroke="#14489D" strokeWidth="1.84688" strokeLinejoin="round"
			/>
			<path d="M6.4043 1.52344L6.435 4.31387" stroke="#14489D" strokeWidth="1.84688" strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M11.9766 1.46094L12.0072 4.25136" stroke="#14489D" strokeWidth="1.84688" strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M17.5488 1.39062L17.5795 4.18105" stroke="#14489D" strokeWidth="1.84688" strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M6.63281 22.451L17.7771 22.3281" stroke="#14489D" strokeWidth="1.84688" strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3912_103819">
				<rect width="24.5449" height="30" fill="white" transform="translate(0 0.5)" />
			</clipPath>
		</defs>
	</svg>
);

export const BuyIcon = (props: SVGAttributes<SVGElement>) => (
	<svg
		width="3.2rem"
		height="3.3rem"
		viewBox="0 0 32 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M8.42188 13.5469L25.685 18.1785L24.312 20.7032L10.5271 21.1243L8.42188 13.5469Z" fill="#CEE2FF" />
		<path
			d="M3.97968 4.47717C5.07468 3.34217 7.55468 6.03717 7.78468 7.01717C8.71802 11.0005 9.63468 14.9872 10.5347 18.9772C10.6947 19.6822 11.4397 20.1222 12.1897 20.1222C15.6264 20.1355 19.0647 20.1388 22.5047 20.1322C23.1547 20.1322 23.9647 19.6772 24.0947 19.1022C24.818 15.9888 25.5197 12.8705 26.1997 9.74717C26.5697 8.02217 25.5447 7.51217 24.0147 7.51217C20.8013 7.50217 17.5897 7.46717 14.3797 7.40717C13.6364 7.39384 13.3014 7.0155 13.3747 6.27217C13.3957 6.06404 13.4927 5.87112 13.647 5.73073C13.8012 5.59034 14.0017 5.51247 14.2097 5.51217C17.233 5.50217 20.248 5.50217 23.2547 5.51217C27.5047 5.52217 28.8547 7.29217 27.9297 11.3272C27.343 13.9005 26.7564 16.4738 26.1697 19.0472C25.6597 21.2722 24.2597 22.1022 21.9597 22.1172C18.9197 22.1438 15.878 22.1438 12.8347 22.1172C10.7847 22.1022 9.13968 21.5522 8.61968 19.5122C8.21968 17.9588 7.35135 14.1555 6.01468 8.10217C5.68968 6.63217 2.81968 5.67717 3.97968 4.47717Z"
			fill="#14489D"
		/>
		<path
			d="M16.0312 14.8143C17.3479 13.6643 18.6229 12.4709 19.8562 11.2343C20.5129 10.5809 21.0629 10.6343 21.5062 11.3943C21.602 11.5575 21.6408 11.7481 21.6164 11.9363C21.5919 12.1245 21.5057 12.2996 21.3712 12.4343L16.5912 17.2243C16.5053 17.3104 16.4032 17.3788 16.2908 17.4255C16.1784 17.4721 16.0579 17.4961 15.9362 17.4961C15.8145 17.4961 15.694 17.4721 15.5816 17.4255C15.4692 17.3788 15.3671 17.3104 15.2812 17.2243L13.2262 15.1643C13.1491 15.087 13.0881 14.9952 13.0466 14.8944C13.0051 14.7935 12.9839 14.6856 12.9844 14.5767C12.9848 14.4679 13.0069 14.3604 13.0493 14.2604C13.0916 14.1604 13.1535 14.07 13.2312 13.9943C14.2412 13.0043 15.0062 14.2243 15.6562 14.8093C15.7829 14.9226 15.9079 14.9243 16.0312 14.8143Z"
			fill="#14489D"
		/>
		<path
			d="M15.9651 26.4972C15.9651 27.1947 15.688 27.8637 15.1948 28.3569C14.7015 28.8501 14.0326 29.1272 13.3351 29.1272C12.6376 29.1272 11.9686 28.8501 11.4754 28.3569C10.9822 27.8637 10.7051 27.1947 10.7051 26.4972C10.7051 25.7997 10.9822 25.1307 11.4754 24.6375C11.9686 24.1443 12.6376 23.8672 13.3351 23.8672C14.0326 23.8672 14.7015 24.1443 15.1948 24.6375C15.688 25.1307 15.9651 25.7997 15.9651 26.4972ZM13.9551 26.4822C13.9551 26.3178 13.8898 26.1601 13.7735 26.0438C13.6572 25.9275 13.4995 25.8622 13.3351 25.8622C13.2537 25.8622 13.173 25.8782 13.0978 25.9094C13.0226 25.9405 12.9542 25.9862 12.8967 26.0438C12.8391 26.1014 12.7934 26.1697 12.7623 26.2449C12.7311 26.3201 12.7151 26.4008 12.7151 26.4822C12.7151 26.5636 12.7311 26.6442 12.7623 26.7194C12.7934 26.7947 12.8391 26.863 12.8967 26.9206C12.9542 26.9782 13.0226 27.0238 13.0978 27.055C13.173 27.0862 13.2537 27.1022 13.3351 27.1022C13.4995 27.1022 13.6572 27.0369 13.7735 26.9206C13.8898 26.8043 13.9551 26.6466 13.9551 26.4822Z"
			fill="#14489D"
		/>
		<path
			d="M23.9712 26.4944C23.9712 27.1932 23.6936 27.8634 23.1994 28.3576C22.7052 28.8518 22.035 29.1294 21.3362 29.1294C20.6373 29.1294 19.9671 28.8518 19.4729 28.3576C18.9788 27.8634 18.7012 27.1932 18.7012 26.4944C18.7012 25.7955 18.9788 25.1253 19.4729 24.6311C19.9671 24.137 20.6373 23.8594 21.3362 23.8594C22.035 23.8594 22.7052 24.137 23.1994 24.6311C23.6936 25.1253 23.9712 25.7955 23.9712 26.4944ZM21.9562 26.4894C21.9562 26.3236 21.8903 26.1646 21.7731 26.0474C21.6559 25.9302 21.4969 25.8644 21.3312 25.8644C21.1654 25.8644 21.0064 25.9302 20.8892 26.0474C20.772 26.1646 20.7062 26.3236 20.7062 26.4894C20.7062 26.6551 20.772 26.8141 20.8892 26.9313C21.0064 27.0485 21.1654 27.1144 21.3312 27.1144C21.4969 27.1144 21.6559 27.0485 21.7731 26.9313C21.8903 26.8141 21.9562 26.6551 21.9562 26.4894Z"
			fill="#14489D"
		/>
	</svg>
);

export const SaleIcon = (props: SVGAttributes<SVGElement>) => (
	<svg
		width="3rem"
		height="2.7rem"
		viewBox="0 0 30 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_3912_103842)">
			<path d="M24.1793 3.0625H17.3809V11.6446H24.1793V3.0625Z" fill="#CEE2FF" />
			<path
				d="M25.8134 5.1874C25.2364 4.01475 23.7358 3.03594 22.707 3.69354C21.6781 4.35115 21.5105 6.39614 22.0875 7.5688L23.6126 10.6684C24.1896 11.8411 27.0065 12.0147 28.0353 11.3571C29.0642 10.6995 27.9154 9.45969 27.3385 8.28704L25.8134 5.1874Z"
				fill="#CEE2FF"
			/>
			<path
				d="M5.33203 21.7459C5.33203 22.6399 5.64363 23.4973 6.19831 24.1295C6.75296 24.7617 7.50524 25.1168 8.28963 25.1168C9.07402 25.1168 9.8263 24.7617 10.3809 24.1295C10.9356 23.4973 11.2472 22.6399 11.2472 21.7459C11.2472 20.8519 10.9356 19.9945 10.3809 19.3623C9.8263 18.7302 9.07402 18.375 8.28963 18.375C7.50524 18.375 6.75296 18.7302 6.19831 19.3623C5.64363 19.9945 5.33203 20.8519 5.33203 21.7459ZM20.1199 21.7459C20.1199 22.6399 20.4315 23.4973 20.9862 24.1295C21.5409 24.7617 22.2931 25.1168 23.0775 25.1168C23.8619 25.1168 24.6142 24.7617 25.1688 24.1295C25.7235 23.4973 26.0351 22.6399 26.0351 21.7459C26.0351 20.8519 25.7235 19.9945 25.1688 19.3623C24.6142 18.7302 23.8619 18.375 23.0775 18.375C22.2931 18.375 21.5409 18.7302 20.9862 19.3623C20.4315 19.9945 20.1199 20.8519 20.1199 21.7459Z"
				stroke="#14489D" strokeWidth="1.3688" strokeLinecap="round" strokeLinejoin="round"
			/>
			<path
				d="M5.13063 21.7566H4.37332C3.26875 21.7566 2.37332 20.8612 2.37332 19.7566V15.0148M0.894531 1.53125H15.1612C16.2658 1.53125 17.1612 2.42668 17.1612 3.53125V21.7566M11.4464 21.7566H19.9127M26.1242 21.7566H26.9915C28.0961 21.7566 28.9915 20.8612 28.9915 19.7566V11.6439M28.9915 11.6439H17.1612M28.9915 11.6439L25.1176 4.28504C24.7717 3.62803 24.0903 3.2167 23.3478 3.2167H17.1612M2.37332 8.27303H8.28846"
				stroke="#14489D" strokeWidth="1.49994" strokeLinecap="round" strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3912_103842">
				<rect width="30" height="26.0271" fill="white" transform="translate(0 0.515625)" />
			</clipPath>
		</defs>
	</svg>
);

export const GetToStorageIcon = (props: SVGAttributes<SVGElement>) => (
	<svg
		width="3.2rem"
		height="2.9rem"
		viewBox="0 0 32 29"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_3912_103852)">
			<path
				d="M12.7546 19.8509L17.1567 21.3184C17.1567 21.3184 28.162 19.1172 29.6294 19.1172C31.0967 19.1172 31.0967 20.5847 29.6294 22.0522C28.162 23.5197 23.0262 27.9222 18.6241 27.9222C14.222 27.9222 11.2872 25.7209 8.35246 25.7209C5.41776 25.7209 1.01562 25.7209 1.01562 25.7209"
				stroke="#14489D" strokeWidth="1.69649" strokeLinecap="round" strokeLinejoin="round"
			/>
			<path
				d="M1.01562 16.9187C2.483 15.4512 5.41776 13.25 8.35246 13.25C11.2872 13.25 18.2572 16.185 19.3578 17.6525C20.4583 19.12 17.1567 21.3213 17.1567 21.3213"
				stroke="#14489D" strokeWidth="1.69649" strokeLinecap="round" strokeLinejoin="round"
			/>
			<path
				d="M9.82031 8.8453V2.97532C9.82031 2.16484 10.4772 1.50781 11.2877 1.50781H28.8961C29.7066 1.50781 30.3635 2.16484 30.3635 2.97532V14.7153"
				stroke="#14489D" strokeWidth="1.69649" strokeLinecap="round" strokeLinejoin="round"
			/>
			<path d="M23.7607 1.50781H16.4238V8.11157H23.7607V1.50781Z" fill="#CEE2FF" stroke="#14489D" strokeWidth="1.69649"
				strokeLinecap="round" strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3912_103852">
				<rect width="32" height="28.447" fill="white" transform="translate(0 0.5)" />
			</clipPath>
		</defs>
	</svg>
);
