// TODO: Добавить типы
/** Апи получения списка наименований рыбопродукции */
export const getFishNamesList = async () => {
	try {
		const response = await fetch(`${process.env.HOST}/api_v1/kind_of_fishes/`);
		const data = await response.json();
		return data;
	}
	catch (error) {
		if (error) {
			console.log(error);
			console.log('Ошибка - /kind_of_fishes/');
		}
		throw error;
	}
};
