import { useCallback } from 'react';

import { useRouter } from 'next/router';

/**
 * Возвращает колбэк для перевода полей из апи
 */
export function useApiTranslate() {
	const router = useRouter();

	return useCallback(
		(ruStr: string, enStr?: string) => {
			return router.locale === 'ru' ? ruStr : enStr ?? ruStr ?? '';
		},
		[router.locale],
	);
}
