import React, { HTMLProps, PropsWithChildren, useEffect, useRef } from 'react';

import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import clsx from 'clsx';

import useOnClickOutside from '@/hooks/use-on-click-outside';

import './Modal.scss';

type ModalProps = HTMLProps<HTMLDialogElement> &
	PropsWithChildren & {
		onClose: () => void;
		// TODO: Переименовать в `size`
		widthSize?: 'default' | 'small' | 'big' | 'tiny';
		cardProps?: HTMLProps<HTMLDivElement>;
	};

export default function Modal(props: ModalProps) {
	const { title, onClose, children, open, className, widthSize, cardProps, style } = props;

	const modal = useRef<HTMLDivElement>();
	const card = useRef<HTMLDivElement>();

	useOnClickOutside(card, onClose);

	useEffect(() => {
		const cardScrollHeight = card.current.scrollHeight;
		const bodyScrollHeight = document.querySelector('body').scrollHeight;

		if (open) {
			if (cardScrollHeight < bodyScrollHeight) {
				disableBodyScroll(modal.current);
			}
		}
		else {
			enableBodyScroll(modal.current);
		}

		return () => {
			clearAllBodyScrollLocks();
		};
	}, [open]);

	return (
		<div ref={modal} className={clsx(className, 'modal', open && 'modal--open')}>
			<div className="modal__container" style={style}>
				<div
					ref={card}
					{...cardProps}
					className={clsx('modal__card', 'relative', widthSize, cardProps?.className)}
				>
					{title && (
						<div className="modal__header flex flex-row justify-between mb-[3rem]">
							<h2 className="modal__title m-0">{title}</h2>
						</div>
					)}

					<div className="modal__body">{children}</div>

					<button
						className="modal__close absolute top-0 right-0 m-[1rem]"
						onClick={onClose}
						aria-label="Закрыть"
					>
						<svg
							className="modal__close-icon"
							width="2.4rem"
							height="2.4rem"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M13.41 11.9963L19.71 5.70628C19.8983 5.51798 20.0041 5.26258 20.0041 4.99628C20.0041 4.72998 19.8983 4.47458 19.71 4.28628C19.5217 4.09798 19.2663 3.99219 19 3.99219C18.7337 3.99219 18.4783 4.09798 18.29 4.28628L12 10.5863L5.71 4.28628C5.5217 4.09798 5.2663 3.99219 5 3.99219C4.7337 3.99219 4.4783 4.09798 4.29 4.28628C4.1017 4.47458 3.99591 4.72998 3.99591 4.99628C3.99591 5.26258 4.1017 5.51798 4.29 5.70628L10.59 11.9963L4.29 18.2863C4.19627 18.3792 4.12188 18.4898 4.07111 18.6117C4.02034 18.7336 3.9942 18.8643 3.9942 18.9963C3.9942 19.1283 4.02034 19.259 4.07111 19.3809C4.12188 19.5027 4.19627 19.6133 4.29 19.7063C4.38296 19.8 4.49356 19.8744 4.61542 19.9252C4.73728 19.9759 4.86799 20.0021 5 20.0021C5.13201 20.0021 5.26272 19.9759 5.38458 19.9252C5.50644 19.8744 5.61704 19.8 5.71 19.7063L12 13.4063L18.29 19.7063C18.383 19.8 18.4936 19.8744 18.6154 19.9252C18.7373 19.9759 18.868 20.0021 19 20.0021C19.132 20.0021 19.2627 19.9759 19.3846 19.9252C19.5064 19.8744 19.617 19.8 19.71 19.7063C19.8037 19.6133 19.8781 19.5027 19.9289 19.3809C19.9797 19.259 20.0058 19.1283 20.0058 18.9963C20.0058 18.8643 19.9797 18.7336 19.9289 18.6117C19.8781 18.4898 19.8037 18.3792 19.71 18.2863L13.41 11.9963Z"
								fill="#0A2653"
								fillOpacity="0.3"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
}
