import axios, { AxiosError } from 'axios';

import { TemperatureRegime } from '@/types/temperature-regime';

/**
 * Отдаёт массив из возможных температур
 */
export const getTemperatures = async (degree = true) => {
	try {
		const { data } = await axios.get<TemperatureRegime[]>(
			`${process.env.HOST}/api_v1/temperature-conditions/`,
		);

		return degree ? [...new Set(data?.map((i) => i.degree)?.sort((a, b) => b - a))] : data;
	}
	catch (error) {
		if (error instanceof AxiosError) {
			console.log(error?.response?.data);
		}
		else {
			console.log(error);
		}
	}
};
