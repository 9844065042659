import React, { FormEvent, useState } from 'react';

import { useRouter } from 'next/router';

import { getCutTypes } from '@/api/get-cut-types';
import { getFishSaleFilter } from '@/api/get-fish-sale-filter';
import { useApiTranslate } from '@/hooks/use-api-translate';
import useAsyncEffect from '@/hooks/use-async-effect';
import useMobile from '@/hooks/use-mobile';
import { useTranslate } from '@/hooks/use-translate';
import Button from '@/UI/Button';
import Container from '@/UI/Container';
import LabeledInput from '@/UI/LabeledInput';
import LabeledSelect from '@/UI/LabeledSelect';

import bgDesktop from './assets/bg-desktop.png';
import bgMobile from './assets/bg-mobile.png';
import { en } from './en';

import cls from './FishSearch.module.scss';

type FishSearchFormData = {
	/** Местоположение */
	location?: string;
	/** Вид ВБР */
	kind_fish?: string;
	/** Тип разделки */
	type_cutting?: string;
	/** Изготовитель */
	product_manufacturer?: string;
	/** Масса нетто, кг */
	volume?: number;
};

type TextAndValue = {
	text: string;
	value: string;
};

export default function FishSearch() {
	const isMobile = useMobile();
	const router = useRouter();
	const [data, setData] = useState<FishSearchFormData>({});
	const [fishProducts, setFishProducts] = useState<TextAndValue[]>([]);
	const [cutTypes, setCutTypes] = useState<TextAndValue[]>([]);
	const t = useTranslate(en);
	const tApi = useApiTranslate();

	// Получает список видов ВБР, задаёт options'ы для формы
	useAsyncEffect(async () => {
		const fishProducts = await getFishSaleFilter();
		// @ts-ignore
		setFishProducts(
			fishProducts?.kind_fish?.map((fishProduct) => ({
				value: String(fishProduct.id),
				text: tApi(fishProduct.title, fishProduct.title_en),
			})),
		);
	}, [router.locale]);

	// Получает список типов разделки, задаёт options'ы для формы
	useAsyncEffect(async () => {
		const cutTypes = await getCutTypes();
		setCutTypes(
			cutTypes.map((cutType) => ({
				value: cutType.id,
				text: tApi(cutType.title, cutType.title_en),
			})),
		);
	}, [router.locale]);

	/**
	 * Обрабатывает отправку формы
	 * Редиректит на страницу "buy-fish" с query-параметрами из формы
	 **/
	const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// TODO: сделать так, чтобы на странице buy-fish обрабатывались все переданные параметры
		await router.push({
			pathname: 'buy-fish',
			query: data,
		});
	};

	return (
		<section
			style={{ backgroundImage: `url(${isMobile ? bgMobile.src : bgDesktop.src})` }}
			className={cls.section}
		>
			<Container className={cls.container}>
				<div className={cls.info}>
					<h2 className={cls.title}>{t('Покупайте рыбопродукцию по выгодной цене')}</h2>
					<p className={cls.subtitle}>
						{t(
							'Покажем вам, на каком складе лежит и продаётся нужная вам рыба, и кто её продаёт',
						)}
					</p>
					<Button className={cls.moreButton} variant="pale-with-border" href="/buy-fish">
						{t('Подробнее')}
					</Button>
				</div>

				<form onSubmit={submitHandler} className={cls.card}>
					<p className={cls.cardTitle}>{t('Поиск рыбы')}</p>
					<div className={cls.formFields}>
						<LabeledInput
							label={t('Местоположение')}
							value={data.location}
							inputtype="location"
							filterFromBound="city"
							filterToBound="city"
							placeholder={t('Введите город или регион')}
							onUpdate={(e) => setData({ ...data, location: e.data.city })}
							onInput={(e) => setData({ ...data, location: e.currentTarget.value })}
						/>
						<div className={cls.formRow}>
							<LabeledSelect
								label={t('ВБР')}
								value={data.kind_fish}
								options={fishProducts}
								placeholder={t('Выберите тип')}
								resetOption={t('Не выбрано')}
								onChange={(product) => setData({ ...data, kind_fish: product })}
							/>
							<LabeledSelect
								label={t('Вид разделки')}
								value={data.type_cutting}
								options={cutTypes}
								placeholder={t('Выберите тип')}
								resetOption={t('Не выбрано')}
								onChange={(cutType) => setData({ ...data, type_cutting: cutType })}
							/>
						</div>
						<div className={cls.formRow}>
							<LabeledInput
								label={t('Изготовитель')}
								value={data.product_manufacturer}
								placeholder={t('Введите изготовителя')}
								onChange={(e) =>
									setData({
										...data,
										product_manufacturer: e.currentTarget.value,
									})
								}
							/>
							<LabeledInput
								label={t('Масса нетто, кг')}
								value={data.volume}
								inputtype="number"
								placeholder={t('Введите массу')}
								onChange={(e) =>
									setData({ ...data, volume: +e.currentTarget.value })
								}
							/>
						</div>
					</div>
					<Button className={cls.submitButton}>{t('Найти рыбу')}</Button>
				</form>
			</Container>
		</section>
	);
}
