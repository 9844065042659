import React, { HTMLProps } from 'react';

import clsx from 'clsx';

import cls from './SliderButton.module.scss';

type SliderButtonProps = HTMLProps<HTMLButtonElement> & {
	direction?: 'prev' | 'next';
};

export default function SliderButton(props: SliderButtonProps) {
	return (
		<button
			onClick={props.onClick}
			disabled={props.disabled}
			className={clsx(
				cls.button,
				props.className,
				props.direction === 'next' ? cls.next : cls.prev,
			)}
		>
			<svg
				className={cls.icon}
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15 18.9998C14.744 18.9998 14.488 18.9018 14.293 18.7068L8.29301 12.7068C7.90201 12.3158 7.90201 11.6838 8.29301 11.2928L14.293 5.29276C14.684 4.90176 15.316 4.90176 15.707 5.29276C16.098 5.68376 16.098 6.31576 15.707 6.70676L10.414 11.9998L15.707 17.2928C16.098 17.6838 16.098 18.3158 15.707 18.7068C15.512 18.9018 15.256 18.9998 15 18.9998Z"
					fill="#ffffff"
				/>
			</svg>
		</button>
	);
}
