import React, { FormEvent, useState } from 'react';
import { DaDataSuggestion } from 'react-dadata';

import { useRouter } from 'next/router';

import { getFishNamesList } from '@/api/get-fish-names-list';
import { getTemperatures } from '@/api/get-temperature';
import useAsyncEffect from '@/hooks/use-async-effect';
import { useTranslate } from '@/hooks/use-translate';
import Button from '@/UI/Button';
import Container from '@/UI/Container';
import LabeledDatePicker from '@/UI/LabeledDatePicker';
import LabeledInput from '@/UI/LabeledInput';
import Input from '@/UI/LabeledInput';
import LabeledSelect from '@/UI/LabeledSelect';

import fishIcon from './assets/fishIcon.svg';
import picture from './assets/picture.png';
import { en } from './en';

import './WarehouseSearch.scss';
import cls from './WarehouseSearch.module.scss';

type WarehouseSearchFormData = {
	/** Местоположение склада */
	location?: string;
	radius?: string;
	coords?: string;
	/** Дата */
	date?: string;
	/** Вид ВБР, title */
	product?: string;
	/** Температурн режим, deegree */
	temperature?: string;
	/** Масса брутто, кг */
	volume?: number;
};

export default function WarehouseSearch() {
	const [data, setData] = useState<WarehouseSearchFormData>({});
	const [fishProducts, setFishProducts] = useState<string[]>([]);
	const [temperatures, setTemperatures] = useState([]);
	const [validationState, setValidationState] = useState({
		coords: {
			valid: false,
			message: '',
		},
		temperature: {
			valid: false,
			message: '',
		},
		volume: {
			valid: false,
			message: '',
		},
	});
	const [isValid, setIsValid] = useState(false);
	const router = useRouter();
	const t = useTranslate(en);

	// Получает список видов ВБР, задаёт options'ы для формы
	useAsyncEffect(async () => {
		const fishProducts = await getFishNamesList();
		setFishProducts(fishProducts?.map((fishProduct) => fishProduct.title));
	}, []);

	// Получает список температурных режимов, задаёт options'ы для формы
	useAsyncEffect(async () => {
		const temperatures = await getTemperatures();
		setTemperatures(temperatures);
	}, []);

	const validate = async (field, isValid = null, message = 'Обязательно к заполнению') => {
		if (isValid === false) {
			setValidationState((prev) => ({
				...prev,
				[field]: {
					valid: false,
					message: message,
				},
			}));
		}
		else if (isValid === true) {
			setValidationState((prev) => ({
				...prev,
				[field]: {
					valid: true,
					message: '',
				},
			}));
		}

		setValidationState((state) => {
			if (
				Object.values(state).filter((v) => v.valid).length ===
				Object.keys(validationState).length
			) {
				setIsValid(true);
			}
			else {
				setIsValid(false);
			}
			return state;
		});
	};

	/**
	 * Обрабатывает отправку формы
	 * Редиректит на страницу "search" с query-параметрами из формы
	 **/
	const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		validate('coords');
		validate('temperature');
		validate('volume');

		if (isValid) {
			router.push({
				pathname: 'search',
				query: data,
			});
		}
	};

	return (
		<section className={cls.section}>
			<div className={cls.picture} style={{ backgroundImage: `url(${picture.src})` }} />

			<Container className={cls.container}>
				<h2 className={cls.title}>
					{t('Найдите лучший склад для хранения рыбы и морепродуктов по всей России')}
				</h2>

				<div className={cls.main}>
					<ul className={cls.fishIconsList}>
						{[...Array(8)].map((_, index) => (
							<li
								key={index}
								className={cls.fishIconItem}
								style={{ backgroundImage: `url(${fishIcon.src})` }}
							/>
						))}
					</ul>
					<form onSubmit={submitHandler} className={cls.card}>
						<p className={cls.cardTitle}>{t('Поиск склада')}</p>
						<div className={cls.formFields}>
							<div className="search-panel__join">
								<Input
									error={validationState['coords'].message}
									onBlur={(e) => {
										validate('coords', Boolean(data.coords));
									}}
									label={`${t('Местоположение')} *`}
									inputtype="location"
									placeholder={t('Введите регион')}
									value={data.location ?? ''}
									onUpdate={(e: DaDataSuggestion<any>) => {
										setData({
											...data,
											location: e.value,
											coords: `${e.data.geo_lat},${e.data.geo_lon}`,
										});
									}}
									onInput={(e) => {
										if (e.currentTarget.value !== data.location) {
											setData({
												...data,
												location: e.currentTarget.value,
												coords: '',
											});
										}

										validate('coords', false, '');
										setIsValid(false);
									}}
								/>
								<Input
									label={t('Радиус поиска, км')}
									disabled={!(data.location && data.coords?.length)}
									inputtype="number"
									placeholder="500"
									tooltip={t('Сначала укажите местоположение')}
									value={data.radius ?? ''}
									maxLength={5}
									onChange={(e) => {
										setData({ ...data, radius: e.currentTarget.value });
									}}
								/>
							</div>
							<div className={cls.formRow}>
								<LabeledDatePicker
									label={t('Дата')}
									placeholder={t('Выберите дату')}
									dateValue={data.date}
									onChange={(date) => setData({ ...data, date })}
								/>
								<LabeledSelect
									error={validationState['temperature'].message}
									label={`${t('Температурный режим')} *`}
									value={data.temperature}
									options={temperatures}
									placeholder={t('Выберите режим')}
									resetOption={t('Не выбрано')}
									onBlur={(e) => {
										validate('temperature', !!data.temperature);
									}}
									onChange={(temperature) => {
										setData({ ...data, temperature });
										validate('temperature', !!temperature);
									}}
									onInput={(e) => {
										const temperature = temperatures.includes(
											parseInt(e.currentTarget.value.trim()),
										)
											? e.currentTarget.value.trim()
											: '';
										setData({ ...data, temperature: temperature });
										validate('temperature', !!temperature);
									}}
								/>
							</div>
							<div className={cls.formRow}>
								<LabeledInput
									error={validationState['volume'].message}
									onBlur={(e) => {
										validate('volume', !!data.volume);
									}}
									label={`${t('Масса брутто, кг')} *`}
									value={data.volume}
									inputtype="number"
									placeholder={t('Введите массу')}
									onChange={(e) => {
										setData({ ...data, volume: +e.currentTarget.value });
										validate('volume', true);
									}}
								/>
							</div>
						</div>
						<Button className={cls.submitButton} disabled={!isValid}>
							{t('Подобрать склад')}
						</Button>
					</form>
				</div>
			</Container>
		</section>
	);
}
