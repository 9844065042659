export const en = {
	'Работаем с большинством складов России': 'We work with the majority of the warehouses in Russia',

	'Расширяем географию присутствия': 'We’re expanding the geography of our presence',

	'С нами работают': 'Our customers include',

	'Москва': 'Moscow',
	'Мурманск': 'Murmansk',
	'Архангельск': 'Arkhangelsk',
	'Новосибирск': 'Novosibirsk',
	'Иркутск': 'Irkutsk',
	'Петропавловск-Камчатский': 'Petropavlovsk-Kamchatsky',
	'Владивосток': 'Vladivostok',

	'ДРП': 'DGP',
	'ВЛАДМОРРЫБПОРТ': 'Vladmorrybport',
	'ДАЛЬКОМХОЛОД': 'Dalkomholod',
	'СИБИРСКИЙ ХОЛОД': 'Sibirsky Holod',
	'АГАМА-ЛОГИСТИК': 'Agama',
	'СК ЕВРАЗИЯ': 'SK Evraziya',
	'ВИКТА': 'Vikta',
	'Доброфлот': 'Dobroflot',
	'Корякморепродукт': 'Koryakmoreprodukt'
};
