export const en = {
	'Начните продавать рыбу в 4 простых шага': 'Begin selling fish in 4 simple steps',

	'Получайте заказы от покупателей и продавайте рыбную продукцию на лучших условиях без посредников': 'Receive orders from customers and sell fish produce at the best terms and without intermediaries',

	'Подробнее': 'Find out more',

	'Регистрация': 'Registration',
	'Получите доступ в личный кабинет': 'Get access to your account',
	'Размещение на складе': 'Placement at the warehouse',
	'Разместите рыбу на хранение в любом рыбном складе России': 'Place fish at any fish warehouse in Russia for storage',
	'Выставление на продажу': 'Sales offering',
	'Выставите желаемый объем на продажу': 'Offer the desired volume for sale',
	'Получение заказов': 'Get orders',
	'Выбирайте лучшее предложение по цене и объему. Совершайте сделку напрямую': 'Choose the best offer in terms of price and volume. Conduct the deal directly',
	'Продать рыбу': 'Sell fish'
};
