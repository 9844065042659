import React, { useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';

import clsx from 'clsx';

import useMobile from '@/hooks/use-mobile';
import { useTranslate } from '@/hooks/use-translate';
import Container from '@/UI/Container';
import SliderButton from '@/UI/SliderButton';

import mapDesktop from './assets/map-desktop.svg';
import mapMobile from './assets/map-mobile.svg';
import { en } from './en';
import StatisticTooltip from './StatisticTooltip';

import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import cls from './WarehousesStatistic.module.scss';

type WarehousesCityStatistic = {
	title: string;
	warehousesCount: number;
	workloadPercent: number;
	desktopPosition: { left?: string; top?: string };
	mobilePosition?: { left?: string; top?: string; display?: 'none' };
};

export default function WarehousesStatistic() {
	const isMobile = useMobile();
	const sliderRef = useRef<AliceCarousel>();
	const t = useTranslate(en);

	return (
		<section className={cls.section}>
			<Container className={cls.mainContainer}>
				<div
					className={cls.map}
					style={{ backgroundImage: `url(${isMobile ? mapMobile.src : mapDesktop.src})` }}
				>
					{warehouses.map((warehouse, index) => (
						<StatisticTooltip
							key={index}
							title={t(warehouse.title)}
							warehousesCount={warehouse.warehousesCount}
							workloadPercent={warehouse.workloadPercent}
							style={isMobile ? warehouse.mobilePosition : warehouse.desktopPosition}
						/>
					))}
				</div>

				<div className={cls.textContainer}>
					<h2 className={cls.title}>{t('Работаем с большинством складов России')}</h2>
					<p className={cls.subtitle}>{t('Расширяем географию присутствия')}</p>
				</div>
			</Container>

			<Container>
				<div className={cls.workWith}>
					<p className={cls.workWithTitle}>{t('С нами работают')}</p>

					<div className={cls.workWithSlider}>
						<SliderButton
							direction="prev"
							className={clsx(cls.sliderButton, cls.prev)}
							onClick={(e) => sliderRef?.current?.slidePrev(e)}
						/>
						<AliceCarousel
							ref={sliderRef}
							autoWidth
							infinite
							disableDotsControls
							disableButtonsControls
							mouseTracking
							items={workWith.map((text, index) => (
								<div key={index} className={cls.workWithItem}>
									{t(text)}
								</div>
							))}
						/>
						<SliderButton
							direction="next"
							className={clsx(cls.sliderButton, cls.next)}
							onClick={(e) => sliderRef?.current?.slideNext(e)}
						/>
					</div>
				</div>
			</Container>
		</section>
	);
}

const workWith: string[] = [
	'ДРП',
	'ВЛАДМОРРЫБПОРТ',
	'ДАЛЬКОМХОЛОД',
	'СИБИРСКИЙ ХОЛОД',
	'АГАМА-ЛОГИСТИК',
	'СК ЕВРАЗИЯ',
	'ВИКТА',
	'Доброфлот',
	'Корякморепродукт',
];

const warehouses: WarehousesCityStatistic[] = [
	{
		title: 'Москва',
		warehousesCount: 8,
		workloadPercent: 70,
		desktopPosition: { top: '36rem', left: '10.5rem' },
		mobilePosition: { display: 'none' },
	},
	{
		title: 'Мурманск',
		warehousesCount: 12,
		workloadPercent: 50,
		desktopPosition: { top: '2rem', left: '30rem' },
		mobilePosition: { display: 'none' },
	},
	{
		title: 'Архангельск',
		warehousesCount: 10,
		workloadPercent: 64,
		desktopPosition: { top: '16rem', left: '42rem' },
		mobilePosition: { display: 'none' },
	},
	{
		title: 'Новосибирск',
		warehousesCount: 14,
		workloadPercent: 80,
		desktopPosition: { top: '31.5rem', left: '40rem' },
		mobilePosition: { display: 'none' },
	},
	{
		title: 'Иркутск',
		warehousesCount: 9,
		workloadPercent: 76,
		desktopPosition: { top: '33rem', left: '67rem' },
		mobilePosition: { display: 'none' },
	},
	{
		title: 'Петропавловск-Камчатский',
		warehousesCount: 8,
		workloadPercent: 20,
		desktopPosition: { left: '96.5rem', top: '12rem' },
		mobilePosition: { left: '6rem', top: '5.5rem' },
	},
	{
		title: 'Владивосток',
		warehousesCount: 25,
		workloadPercent: 50,
		desktopPosition: { left: '92.5rem', top: '34.8rem' },
		mobilePosition: { left: '13.5rem', top: '25.5rem' },
	},
];
