// TODO: Добавить типы
/** Апи получения списка типов разделки */
export const getCutTypes = async () => {
	try {
		const response = await fetch(`${process.env.HOST}/api_v1/cut_types/`);
		const data = await response.json();

		return data;
	}
	catch (error) {
		if (error) {
			console.log(error);
			console.log('Ошибка - /api_v1/cut_types/');
		}
		throw error;
	}
};
