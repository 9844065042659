import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { createApplicationPayment } from '@/api/create-application-payment';
import TariffsTable from '@/components/TariffsTable';
import { REDIRECT_TO_QUERY } from '@/constants/authorization';
import { UserContext } from '@/context/user.context';
import useMobile from '@/hooks/use-mobile';
import { useTranslate } from '@/hooks/use-translate';
import { CompanyType } from '@/types/common-entities';
import { Tariff } from '@/types/tariff';
import Button from '@/UI/Button';
import Container from '@/UI/Container';
import Modal from '@/UI/Modal';

import { FishIcons, FishIconsMobile } from './images/fishes';
import { en } from './en';

import styles from './Tariffs.module.scss';

type TariffProps = {
	tariffsData?: Tariff.TariffsInfoResponse;
};

export const Tariffs: FC<TariffProps> = ({ tariffsData }) => {
	const router = useRouter();
	const { user, balance } = useContext(UserContext);
	const isMobile = useMobile();
	const [isAcceptedModalOpen, setIsAcceptedModalOpen] = useState(false);
	const [tariffChoiceDisable, setTariffChoiceDisable] = useState(false);
	const [userIsStorage, setUserIsStorage] = useState(false);

	const t = useTranslate(en);

	/** Пользователь авторизован? */
	const authed = useMemo(() => Boolean(user), [user]);

	const onTariffChoiceHandler = async (params: {
		tariffInfo: Tariff.TariffWithSettings;
		perYear?: boolean;
		per_three_month?: boolean;
	}) => {
		if (!authed) {
			return router.push(`/registration?${REDIRECT_TO_QUERY}=${router.asPath}`);
		}

		try {
			setTariffChoiceDisable(true);
			if (params.perYear) {
				await createApplicationPayment({
					company: balance?.company?.id,
					type: 1,
					tariff: params.tariffInfo.id,
					per_year: params.perYear,
				});
			} else {
				await createApplicationPayment({
					company: balance?.company?.id,
					type: 1,
					tariff: params.tariffInfo.id,
					per_three_month: params.per_three_month,
				});
			}
			setIsAcceptedModalOpen(true);
		} catch (error) {
			console.log(error);
		} finally {
			setTariffChoiceDisable(false);
		}
	};

	useEffect(() => {
		if (user) {
			setUserIsStorage(user.company_type === CompanyType.Warehouse);
		}
	}, [user]);

	return (
		<section className={styles.section}>
			<Container>
				{isMobile ? (
					<FishIconsMobile className={styles.fishes} />
				) : (
					<FishIcons className={styles.fishes} />
				)}
				{!isMobile && <h2 className={styles.mobileTitle}>{t('Тарифы')}</h2>}
				<div className={styles.card} id="tariffs">
					<TariffsTable
						bestChoice={2}
						maxHeight="40rem"
						tariffsData={tariffsData}
						userIsAuth={authed}
						onTariffChoice={onTariffChoiceHandler}
						tariffChoiceDisable={tariffChoiceDisable}
						currentTariff={balance?.tariff?.id}
						currentPerYear={balance?.perYear}
						showButtons={!userIsStorage}
						isActiveTariff={balance?.activeTariff}
					/>
				</div>
			</Container>

			<Modal
				open={isAcceptedModalOpen}
				onClose={() => setIsAcceptedModalOpen(false)}
				widthSize="tiny"
				title={t('Заявка на смену тарифа принята')}
			>
				<p className="opacity-[.64] m-0 mb-[3rem]">
					{t('Наш менеджер свяжется с вами в скором времени.')}
				</p>
				<Button
					className="ml-[auto] mr-[auto] sm:w-[unset] w-[100%]"
					onClick={() => setIsAcceptedModalOpen(false)}
				>
					{t('Хорошо')}
				</Button>
			</Modal>
		</section>
	);
};
