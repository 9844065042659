import React from 'react';

import useMobile from '@/hooks/use-mobile';
import { useTranslate } from '@/hooks/use-translate';
import Button from '@/UI/Button';
import Container from '@/UI/Container';

import bgDesktop from './assets/bg-desktop.svg';
import { BasketUpIcon, BoxSeamIcon, ChecklistIcon, FishIcon } from './assets/icons';
import { en } from './en';

import cls from './StartSale.module.scss';

const list: { icon: JSX.Element; title: string; subtitle: string }[] = [
	{
		icon: <ChecklistIcon className={cls.icon} />,
		title: 'Регистрация',
		subtitle: 'Получите доступ в личный кабинет',
	},
	{
		icon: <BoxSeamIcon className={cls.icon} />,
		title: 'Размещение на складе',
		subtitle: 'Разместите рыбу на хранение в любом рыбном складе России',
	},
	{
		icon: <FishIcon className={cls.icon} />,
		title: 'Выставление на продажу',
		subtitle: 'Выставите желаемый объем на продажу',
	},
	{
		icon: <BasketUpIcon className={cls.icon} />,
		title: 'Получение заказов',
		subtitle: 'Выбирайте лучшее предложение по цене и объему. Совершайте сделку напрямую',
	},
];

export default function StartSale() {
	const isMobile = useMobile();
	const t = useTranslate(en);

	return (
		<section className={cls.section}>
			<Container className={cls.mainContainer}>
				<h2 className={cls.title}>{t('Начните продавать рыбу в 4 простых шага')}</h2>
				<p className={cls.subtitle}>
					{t(
						'Получайте заказы от покупателей и продавайте рыбную продукцию на лучших условиях без посредников',
					)}
				</p>
				<Button href="#" className={cls.more} variant="pale-with-border">
					{t('Подробнее')}
				</Button>
			</Container>

			<div
				className={cls.background}
				style={{ backgroundImage: !isMobile && `url(${bgDesktop.src})` }}
			>
				<Container>
					<ul className={cls.list}>
						{list.map((item, index) => (
							<li key={index} className={cls.item}>
								<div className={cls.iconContainer}>{item.icon}</div>
								<p className={cls.itemTitle}>{t(item.title)}</p>
								<p className={cls.itemSubtitle}>{t(item.subtitle)}</p>
							</li>
						))}
					</ul>

					<div className={cls.actions}>
						<Button className={cls.action} href="/personal-account/fish-products">
							{t('Продать рыбу')}
						</Button>
						{/*<Button*/}
						{/*	ClassName={cls.action}*/}
						{/*	Variant="pale"*/}
						{/*	Href="#"*/}
						{/*>*/}
						{/*	Подробнее*/}
						{/*</Button>*/}
					</div>
				</Container>
			</div>
		</section>
	);
}
