export const en = {
	'Покупайте рыбопродукцию по выгодной цене': 'Purchase fish at a beneficial price',

	'Покажем вам, на каком складе лежит и продаётся нужная вам рыба, и кто её продаёт':
		'We show you at which warehouse the fish you need is stored and who sells it',

	Подробнее: 'Find out more',
	'Поиск рыбы': 'Search for fish',
	Местоположение: 'Location',
	ВБР: 'Fish name',
	'Выберите тип': 'Select type',
	'Вид разделки': 'Type of cutting',
	Изготовитель: 'Manufacturer',
	'Введите изготовителя': 'Select manufacturer',
	'Масса нетто, кг': 'Net weight, kg',
	'Введите массу': 'Enter mass',
	'Введите город или регион': 'Select city or region',
	'Найти рыбу': 'Search for fish',
	'Не выбрано': 'Not selected',
};
