import React, { HTMLProps } from 'react';

import { useRouter } from 'next/router';

import clsx from 'clsx';

import cls from './StatisticTooltip.module.scss';

type StatisticTooltipProps = HTMLProps<HTMLDivElement> & {
	title: string;
	warehousesCount: number;
	workloadPercent: number;
};

export default function StatisticTooltip(props: StatisticTooltipProps) {
	const { className, title, warehousesCount, workloadPercent } = props;
	const router = useRouter();
	const isEn = router.locale === 'en';

	return (
		<div className={clsx(cls.container, className)} style={props.style}>
			<p className={cls.title}>{title}</p>
			<dl className={cls.list}>
				<div>
					<dt className={cls.value}>{warehousesCount}</dt>
					<dd className={cls.key}>{isEn ? 'Warehouses' : 'Складов'}</dd>
				</div>
				<div>
					<dt className={cls.value}>{workloadPercent}%</dt>
					<dd className={cls.key}>{isEn ? 'Av. workload' : 'Ср. загруженность'}</dd>
				</div>
			</dl>
		</div>
	);
}
