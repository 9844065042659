import { SVGAttributes } from 'react';

export const FishIcons = (props: SVGAttributes<SVGElement>) => (
	<svg viewBox="0 0 1098 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g id="Page-1" stroke="none" opacity="0.1">
			<g id="fish" stroke="#FFFFFF" transform="translate(1, 1.1982)">
				<g id="Group" transform="translate(0, 0)">
					<path
						d="M9.51100252,8.99996 C13.0722025,-0.00029 33.8454025,-4.80029 38.0000025,8.99996 C36.2194025,17.99996 16.0398025,21.59976 9.51100252,8.99996 Z M9.51100252,8.99996 C9.51100252,8.99996 2.38879252,17.99996 0.608242519,17.99996 C0.303362519,17.99996 0.189892519,17.73616 0.211232519,17.29876 C0.483252519,11.72206 0.0780225189,6.39603 0.00020251894,0.81321 C-0.00681748106,0.30929 0.168132519,0 0.608242519,0 C2.98232252,0 9.51100252,8.99996 9.51100252,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0977025,8.40006 C29.0977025,6.00005 32.6588025,6.0001 32.6588025,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(51, 0)">
					<path
						d="M9.51100144,8.99996 C13.0722014,-0.00029 33.8454014,-4.80029 38.0000014,8.99996 C36.2194014,17.99996 16.0398014,21.59976 9.51100144,8.99996 Z M9.51100144,8.99996 C9.51100144,8.99996 2.38880144,17.99996 0.608201445,17.99996 C0.303401445,17.99996 0.189901445,17.73616 0.211201445,17.29876 C0.483301445,11.72206 0.0780014446,6.39603 0.000201444567,0.81321 C-0.00679855543,0.30929 0.168101445,0 0.608201445,0 C2.98230144,0 9.51100144,8.99996 9.51100144,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0977014,8.40006 C29.0977014,6.00005 32.6588014,6.0001 32.6588014,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(101.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(152.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(203.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(254.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(305.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(356.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(407.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(458.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(509.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group-Copy" transform="translate(560.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(851.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(902.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(953.9998, 0)">
					<path
						d="M9.51120134,8.99996 C13.0722013,-0.00029 33.8452013,-4.80029 38.0002013,8.99996 C36.2192013,17.99996 16.0402013,21.59976 9.51120134,8.99996 Z M9.51120134,8.99996 C9.51120134,8.99996 2.38920134,17.99996 0.608201337,17.99996 C0.303201337,17.99996 0.190201337,17.73616 0.211201337,17.29876 C0.483201337,11.72206 0.0782013366,6.39603 0.000201336632,0.81321 C-0.00679866337,0.30929 0.168201337,0 0.608201337,0 C2.98220134,0 9.51120134,8.99996 9.51120134,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.0982013,8.40006 C29.0982013,6.00005 32.6592013,6.0001 32.6592013,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(1004.9996, 0)">
					<path
						d="M9.51039347,8.99996 C13.0703935,-0.00029 33.8503935,-4.80029 38.0003935,8.99996 C36.2203935,17.99996 16.0403935,21.59976 9.51039347,8.99996 Z M9.51039347,8.99996 C9.51039347,8.99996 2.39039347,17.99996 0.61039347,17.99996 C0.30039347,17.99996 0.19039347,17.73616 0.21039347,17.29876 C0.48039347,11.72206 0.0803934703,6.39603 0.000393470333,0.81321 C-0.00960652967,0.30929 0.17039347,0 0.61039347,0 C2.98039347,0 9.51039347,8.99996 9.51039347,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.1003935,8.40006 C29.1003935,6.00005 32.6603935,6.0001 32.6603935,8.40006"
						id="Path"
					></path>
				</g>
				<g id="Group" transform="translate(1055.9996, 0)">
					<path
						d="M9.51039347,8.99996 C13.0703935,-0.00029 33.8503935,-4.80029 38.0003935,8.99996 C36.2203935,17.99996 16.0403935,21.59976 9.51039347,8.99996 Z M9.51039347,8.99996 C9.51039347,8.99996 2.39039347,17.99996 0.61039347,17.99996 C0.30039347,17.99996 0.19039347,17.73616 0.21039347,17.29876 C0.48039347,11.72206 0.0803934703,6.39603 0.000393470333,0.81321 C-0.00960652967,0.30929 0.17039347,0 0.61039347,0 C2.98039347,0 9.51039347,8.99996 9.51039347,8.99996 Z"
						id="Shape"
					></path>
					<path
						d="M29.1003935,8.40006 C29.1003935,6.00005 32.6603935,6.0001 32.6603935,8.40006"
						id="Path"
					></path>
				</g>
			</g>
		</g>
	</svg>
);

export const FishIconsMobile = (props: SVGAttributes<SVGElement>) => (
	<svg viewBox="0 0 348 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g opacity="0.1">
			<path
				d="M11.511 11C15.0722 1.99971 35.8454 -2.80029 40 11C38.2194 20 18.0398 23.5997 11.511 11ZM11.511 11C11.511 11 4.38879 20 2.60824 20C2.30336 20 2.18989 19.7361 2.21123 19.2988C2.48325 13.722 2.07802 8.39603 2.0002 2.8132C1.99318 2.30929 2.16813 2 2.60824 2C4.98232 2 11.511 11 11.511 11Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M31.0977 10.4C31.0977 7.99993 34.6588 7.99998 34.6588 10.4"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M62.511 11C66.0722 1.99971 86.8454 -2.80029 91 11C89.2194 20 69.0398 23.5997 62.511 11ZM62.511 11C62.511 11 55.3888 20 53.6082 20C53.3034 20 53.1899 19.7361 53.2112 19.2988C53.4833 13.722 53.078 8.39603 53.0002 2.8132C52.9932 2.30929 53.1681 2 53.6082 2C55.9823 2 62.511 11 62.511 11Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M82.0977 10.4C82.0977 7.99993 85.6588 7.99998 85.6588 10.4"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M113.511 11C117.072 1.99971 137.845 -2.80029 142 11C140.219 20 120.04 23.5997 113.511 11ZM113.511 11C113.511 11 106.389 20 104.608 20C104.303 20 104.19 19.7361 104.211 19.2988C104.483 13.722 104.078 8.39603 104 2.8132C103.993 2.30929 104.168 2 104.608 2C106.982 2 113.511 11 113.511 11Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M133.098 10.4C133.098 7.99993 136.659 7.99998 136.659 10.4"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M164.511 11C168.072 1.99971 188.845 -2.80029 193 11C191.219 20 171.04 23.5997 164.511 11ZM164.511 11C164.511 11 157.389 20 155.608 20C155.303 20 155.19 19.7361 155.211 19.2988C155.483 13.722 155.078 8.39603 155 2.8132C154.993 2.30929 155.168 2 155.608 2C157.982 2 164.511 11 164.511 11Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M184.098 10.4C184.098 7.99993 187.659 7.99998 187.659 10.4"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M215.511 11C219.072 1.99971 239.845 -2.80029 244 11C242.219 20 222.04 23.5997 215.511 11ZM215.511 11C215.511 11 208.389 20 206.608 20C206.303 20 206.19 19.7361 206.211 19.2988C206.483 13.722 206.078 8.39603 206 2.8132C205.993 2.30929 206.168 2 206.608 2C208.982 2 215.511 11 215.511 11Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M235.098 10.4C235.098 7.99993 238.659 7.99998 238.659 10.4"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M266.511 11C270.072 1.99971 290.845 -2.80029 295 11C293.219 20 273.04 23.5997 266.511 11ZM266.511 11C266.511 11 259.389 20 257.608 20C257.303 20 257.19 19.7361 257.211 19.2988C257.483 13.722 257.078 8.39603 257 2.8132C256.993 2.30929 257.168 2 257.608 2C259.982 2 266.511 11 266.511 11Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M286.098 10.4C286.098 7.99993 289.659 7.99998 289.659 10.4"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<g opacity="0.1">
			<path
				d="M317.511 11C321.072 1.99971 341.845 -2.80029 346 11C344.219 20 324.04 23.5997 317.511 11ZM317.511 11C317.511 11 310.389 20 308.608 20C308.303 20 308.19 19.7361 308.211 19.2988C308.483 13.722 308.078 8.39603 308 2.8132C307.993 2.30929 308.168 2 308.608 2C310.982 2 317.511 11 317.511 11Z"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinejoin="round"
			/>
			<path
				d="M337.098 10.4C337.098 7.99993 340.659 7.99998 340.659 10.4"
				stroke="white"
				strokeWidth="3.20921"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	</svg>
);
