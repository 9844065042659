import { SVGAttributes } from 'react';

export const ChecklistIcon = (props: SVGAttributes<SVGElement>) => (
	<svg
		width="64px"
		height="64px"
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M25.6399 53.3346H18.6666C17.2521 53.3346 15.8955 52.7727 14.8953 51.7725C13.8952 50.7723 13.3333 49.4158 13.3333 48.0013V16.0013C13.3333 14.5868 13.8952 13.2303 14.8953 12.2301C15.8955 11.2299 17.2521 10.668 18.6666 10.668H39.9999C41.4144 10.668 42.771 11.2299 43.7712 12.2301C44.7713 13.2303 45.3333 14.5868 45.3333 16.0013V37.3346M37.3333 50.668L42.6666 56.0013L53.3333 45.3346M23.9999 21.3346H34.6666M23.9999 32.0013H29.3333"
			stroke="#14489D" strokeWidth="5.3368" strokeLinecap="round" strokeLinejoin="round"
		/>
	</svg>
);

export const BoxSeamIcon = (props: SVGAttributes<SVGElement>) => (
	<svg
		width="64px"
		height="64px"
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M53.3332 19.9958L31.9998 7.99609L10.6665 19.9958M53.3332 19.9958V43.9953L31.9998 55.995M53.3332 19.9958L31.9998 31.9955M31.9998 55.995L10.6665 43.9953V19.9958M31.9998 55.995V31.9955M10.6665 19.9958L31.9998 31.9955M21.8665 26.129L42.1332 13.8626"
			stroke="#14489D" strokeWidth="5.33674" strokeLinecap="round" strokeLinejoin="round"
		/>
	</svg>
);

export const FishIcon = (props: SVGAttributes<SVGElement>) => (
	<svg
		width="64px"
		height="64px"
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M44.5064 19.8359C41.5937 23.217 39.9941 27.533 39.9998 31.9957C39.993 36.4717 41.6017 40.7998 44.5304 44.1847"
			stroke="#14489D" strokeWidth="5.33674" strokeLinecap="round" strokeLinejoin="round"
		/>
		<path
			d="M5.33325 25.3401C25.9066 48.3983 44.6666 52.7128 58.6666 32.0013C44.6666 11.3058 25.9066 15.623 5.33325 38.6785M47.9999 29.3294V29.356"
			stroke="#14489D" strokeWidth="5.33674" strokeLinecap="round" strokeLinejoin="round"
		/>
		<path d="M30.6674 27.9922C28.8876 30.6588 28.8876 33.3254 30.6674 35.992" stroke="#14489D" strokeWidth="5.33674"
			strokeLinecap="round" strokeLinejoin="round"
		/>
	</svg>
);

export const BasketUpIcon = (props: SVGAttributes<SVGElement>) => (
	<svg
		width="64px"
		height="64px"
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M43.5728 24.3201L38.2394 8.3205M16.9061 24.3201L22.2394 8.3205M30.2394 50.9862H17.5568C15.6458 50.9861 13.798 50.302 12.3478 49.0577C10.8975 47.8134 9.94055 46.091 9.65009 44.2023L6.30343 25.1308C6.18654 24.3708 6.2354 23.5944 6.44664 22.8551C6.65788 22.1157 7.02652 21.4307 7.52728 20.8472C8.02804 20.2636 8.64907 19.7952 9.34781 19.4741C10.0465 19.153 10.8064 18.9868 11.5754 18.9869H48.9061C49.6751 18.9868 50.435 19.153 51.1337 19.4741C51.8324 19.7952 52.4535 20.2636 52.9542 20.8472C53.455 21.4307 53.8236 22.1157 54.0349 22.8551C54.2461 23.5944 54.295 24.3708 54.1781 25.1308L53.2234 30.5707"
			stroke="#14489D" strokeWidth="5.33674" strokeLinecap="round" strokeLinejoin="round"
		/>
		<path
			d="M48.9063 56.3186V40.3189M48.9063 40.3189L56.9063 48.3188M48.9063 40.3189L40.9063 48.3188M24.9062 34.9857C24.9062 36.4002 25.4682 37.7567 26.4683 38.7569C27.4685 39.7571 28.8251 40.3189 30.2396 40.3189C31.6541 40.3189 33.0106 39.7571 34.0108 38.7569C35.011 37.7567 35.5729 36.4002 35.5729 34.9857C35.5729 33.5713 35.011 32.2148 34.0108 31.2146C33.0106 30.2144 31.6541 29.6525 30.2396 29.6525C28.8251 29.6525 27.4685 30.2144 26.4683 31.2146C25.4682 32.2148 24.9062 33.5713 24.9062 34.9857Z"
			stroke="#14489D" strokeWidth="5.33674" strokeLinecap="round" strokeLinejoin="round"
		/>
	</svg>
);
